
import { Action } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormTextarea, FormSubmit } from "@/components/forms";
import LayoutModal from "@/layouts/components/modal.vue";
import { bus } from "@/main";
import { CreateReviewPayload, Review } from "@/store/modules/review.store";
import { HasFormErrors } from "@/mixins/has-form-errors";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormTextarea,
        FormSubmit,
    },
})
export default class ReviewOfficeModal extends Mixins(HasFormErrors) {
    @Action("review/create") createReview!: (payload: CreateReviewPayload) => Promise<Review>;

    show = false;

    scoreTimeout: null | number = null;

    hovering = -1;

    payload: CreateReviewPayload | null = null;

    @Ref() modal!: LayoutModal;

    @Ref() message!: FormTextarea;

    mounted() {
        bus.$off("show-office-review");
        bus.$on("show-office-review", (payload: { activity_id: number; account_id: number; type: "AFTER_PUBLICATION" | "AFTER_SALE" | "AFTER_SALES_AGREEMENT" }) => {
            this.payload = {
                score: -1,
                message: "",
                activity_id: payload.activity_id,
                account_id: payload.account_id,
                type: payload.type,
            };
            this.show = true;
            this.modal.focus();
        });
    }

    async submit(form: FormClass) {
        try {
            await this.createReview(this.payload!);

            this.handleClose();

            this.$toast.open({ message: this.$t("views.seller.review_office.success_message") as string, type: "success", position: "bottom-right" });
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            form.reset();
        }
    }

    pickScore(score: number) {
        this.payload!.score = score;
        setTimeout(() => {
            this.hovering = -1;
            this.message.input.focus();
        }, 100);
    }

    setHover(score: number) {
        if (this.scoreTimeout) clearTimeout(this.scoreTimeout);
        this.scoreTimeout = null;
        this.hovering = score;
    }

    clearHover() {
        this.scoreTimeout = setTimeout(() => {
            this.hovering = -1;
            this.scoreTimeout = null;
        }, 100);
    }

    handleClose() {
        localStorage.setItem(`hubr-hide-review-${this.payload!.type}`, "true");
        this.payload = null;
        this.show = false;
    }

    get noScorePicked() {
        return !this.payload || !this.payload.score || this.payload.score <= 0;
    }
}
